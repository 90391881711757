



























import { Component, Vue } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'

const SVGSad = () => import('@/assets/images/svg/sad.svg?inline')

@Component({
  components: {
    SVGSad, QrcodeVue
  }
})
export default class CardPaymentError extends Vue {
  botUrl = 'https://t.me/WorkEat_bot'
}
